<template>
  <div class="search">
    <input type="text" v-model="searchText" @input="search" />
    <div class="results" v-if="results">
        <ul >
            <li v-for="(result, i) in results" :key="i" @click="selectResult(i)">{{ result.first_name }}&nbsp;{{ result.last_name }}</li>
        </ul>
    </div>
  </div>
</template>

<script>

export default {
    name: "Search",
    props: {     
        searchData: {
            type: Object  

        },
    },
    data: function() {
        return {
            searchText: '',
            results: false,
            selected: []
        }
    },
    methods: {
        search () {
            if (this.searchData.length < 3) {return;}
            let $this = this;
            let r = {}
            for (const [key, obj] of Object.entries($this.searchData)) {
                if (obj.last_name.toLowerCase().indexOf(this.searchText.toLowerCase()) > -1) {
                    r[key] = obj
                }
                if (obj.first_name.toLowerCase().indexOf(this.searchText.toLowerCase()) > -1) {
                    r[key] = obj
                }
                
            }
            $this.results = r;
            //$this.results = $this.searchData.filter(e => e.last_name.toLowerCase().indexOf(this.searchText.toLowerCase()) > -1);                 
        },
        selectResult (item) {
            
            this.$emit('updatesearch', item);
            this.searchText = ''
            this.results = false
        }
    }

}

</script>

<style scoped>
    ul {list-style: none; margin: 0; padding: 0;}
    li {padding: 2px 0; cursor: pointer;}
    li:hover {background-color: grey; color: white;} 
    .results {
        width: 100%;
        max-width: 400px;
        border: 1px solid black;
        padding: 2px;
        margin: auto;
        background: lightblue;
    }
</style>