<template>
    <div class="table">
        <table>
            <tr><th>Player</th><th>Average Score</th><th>Pts</th><th>Reb</th><th>Ast</th><th>Stl</th><th>Blk</th><th>TO</th></tr>
            <tr v-for="player in selected" :key="player">
                <td>{{ getPlayerName(player) }} <a href="#" @click.stop.prevent="showdata(player)">Details</a>  |   <a href="#" @click.stop.prevent="remove(player)">X</a></td>
                <td>{{ getStat('total', player) }}</td>
                <td>{{ getStat('pts', player) }}</td>
                <td>{{ getStat('reb', player) }}</td>
                <td>{{ getStat('ast', player) }}</td>
                <td>{{ getStat('stl', player) }}</td>
                <td>{{ getStat('blk', player) }}</td>
                <td>{{ getStat('turnover', player) }}</td>
            </tr>
        </table>
        <div id="dataModal" v-if="showModal">
            <h2>{{ modalData.first_name }} {{ modalData.last_name }}</h2>
            <table>
                 <tr><th>Date</th><th>Game Score</th><th>Pts</th><th>Reb</th><th>Ast</th><th>Stl</th><th>Blk</th><th>TO</th></tr>
                 <tr v-for="(game, i) in modalData.stats" :key="i">
                    <td>{{ getDate(game) }}</td>
                    <td>{{ getGameScore(game) }}</td>
                    <td>{{ game.pts }}</td>
                    <td>{{ game.reb }}</td>
                    <td>{{ game.ast }}</td>
                    <td>{{ game.stl }}</td>
                    <td>{{ game.blk }}</td>
                    <td>{{ game.turnover }}</td>

                 </tr>
            </table>

        </div>
    </div>
</template>


<script>
export default {
    name: "Table",
    props: {     
        players: {
            type: Object  
        },
        selected: {
            type: Array
        }
    },
    data: function () {
        return {
            showModal: false,
            modalData: {}
        }
    },
    methods: {
        getPlayerName (playerID) {       
            return this.players[playerID].first_name + " " + this.players[playerID].last_name;
        },
        getStat (stat, playerID) {
            if (stat == 'total') {
                let total = this.players[playerID].averages['pts'] + this.players[playerID].averages['reb'] + (this.players[playerID].averages['ast']*2) + (this.players[playerID].averages['blk']*3) + (this.players[playerID].averages['stl']*3) - this.players[playerID].averages['turnover'];
                return total;
            }
            if (this.players[playerID].averages && this.players[playerID].averages[stat]) {
                let avg = this.players[playerID].averages[stat];
                return avg.toPrecision(2)
            }
            return '';                   
        },
        remove (playerID) {
            console.log(playerID + ' removing')
            this.$emit('removeplayer', playerID);
        },
        showdata (playerID) {
            this.showModal = true;
            this.modalData = this.players[playerID]


            console.log(this.modalData.stats);
            let s = this.modalData.stats.sort((function(a, b) {
                var keyA = new Date(a.id),
                    keyB = new Date(b.id);
                // Compare the 2 dates
                if (keyA < keyB) return 1;
                if (keyA > keyB) return -1;
                return 0;
                }));
            console.log(s);
            this.modalData.stats = s;
            
        },
        getDate (game) {
            return game.game.date.substring(0, 10);
        },
        getGameScore (game) {
            let total = game.pts + game.reb + (game.ast*2) + (game.blk*3) + (game.stl*3) - game.turnover;
            return total;
        }
    }
}
</script>

<style scoped>
    table {margin: auto; border-collapse: collapse}
    tr, th, td {border: 1px solid black; padding: 2px;}
</style>