<template>
  <div class="hello">
    <div v-if="loadingPlayers">Loading Players...</div>
    <Search :search-data="players" @updatesearch="updateSearch" />
    <Table :players="players" :selected="selected" @removeplayer="removePlayer" />
  </div>
</template>

<script>
import axios from 'axios'
import Search from "./Search"
import Table from "./Table"
export default {
  name: 'HelloWorld',
  components: {
    Search, Table
  },
  data: function() {
    return {
      players: {},
      nextpage: 1,
      playerLoading: false,
      playersLoaded: [],
      loadingPlayers: true,
      selected: []
    }
  },
  created () {
    this.loadData()
    
  },
  methods: {
    async loadData () {
      let $this = this

      if ($this.getLocal('players')) {
        $this.players = JSON.parse($this.players);
        $this.loadingPlayers = false
      }
      else {
        // give a little more than a second between
        $this.playerLoading = setInterval(function() {
          if ($this.playersLoaded.includes($this.nextpage)) {
            console.log('players includes ' + $this.nextpage + '- not rerunning');
          }
          else {
            $this.playersLoaded.push($this.nextpage);
            $this.getPlayerPage($this.nextpage);        
          }
          
        }, 1100)
      }
      
      
    },
    getPlayerPage (page) {
      let $this = this;
       axios.get("https://www.balldontlie.io/api/v1/players?per_page=100&page=" + page)
          .then((data) => {
            let p = data.data
            let newData = {};
            for (const n in p.data) {
              newData[p.data[n].id] = p.data[n]
            }
            
            $this.players = {...$this.players, ...newData};
            
            $this.nextpage = p.meta.next_page
            if (!$this.nextpage) {
              $this.loadingPlayers = false;
              clearInterval($this.playerLoading);
              $this.saveToLocal('players', JSON.stringify($this.players));
            }
            return;
          })
    },
    saveToLocal(key, data) {
      localStorage.setItem(key, data);
    },
    getLocal (key) {
      if (localStorage.getItem(key)) {
        this[key] = localStorage.getItem(key);
        return this[key]
      }
      return false;
    },
    async updateSearch (item) {
      if (!this.selected.includes(item)) {
        await this.getPlayerData(item)
        this.selected.push(item)
        
      }      
    },
    removePlayer (playerID) {
      console.log('removing from top level ' + playerID)
      console.log(this.selected)

      const index = this.selected.indexOf(playerID);
      if (index > -1) {
        this.selected.splice(index, 1);
      }
      
      console.log(this.selected)
    },

    async getPlayerData (playerID) {
      let $this = this;
      let url = "https://www.balldontlie.io/api/v1/stats?seasons[]=2020&player_ids[]=" + playerID;
      let data = await axios.get(url)
        
          let d = data.data.data;
          for (let i = 0; i < d.length; i++) {
            // remove non-events
            
            let a = d[i];
            if (a.ast == 0 && a.blk == 0 && a.reb == 0 && a.pts == 0 && a.stl == 0 && a.turnover == 0) {
              d.splice(i, 1);
            }
            
          }
          $this.players[playerID].stats = d;

          let statsArray = ['pts', 'reb', 'ast', 'blk', 'stl', 'turnover'];
          let stats = $this.players[playerID].stats;
          $this.players[playerID].averages = {}
          statsArray.forEach(stat => {
            let tot = 0;

            for (let i = 0; i < stats.length; i++) {

                tot += stats[i][stat];
            }
            let avg = tot / stats.length;
            
            $this.players[playerID].averages[stat] = avg;
          });      
          return;    
        
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
